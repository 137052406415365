import React from "react"
import "./style.css"

import icon_logo from "../../images/capabilities/logo-icon.png"
import headerImg from "../../images/capabilities/header1.png"

import cert1 from "../../images/capabilities/cert1.png"
import cert2 from "../../images/capabilities/cert2.png"
import cert3 from "../../images/capabilities/cert3.png"
import cert4 from "../../images/capabilities/cert4.png"
import cert5 from "../../images/capabilities/cert5.png"
import cert6 from "../../images/capabilities/cert6.png"
import cert7 from "../../images/capabilities/cert7.png"
import cert8 from "../../images/capabilities/cert8.png"
import cert9 from "../../images/capabilities/cert9.png"

import client1 from "../../images/capabilities/client1.png"
import client2 from "../../images/capabilities/client2.png"
import client3 from "../../images/capabilities/client3.png"
import client4 from "../../images/capabilities/client4.png"
import client5 from "../../images/capabilities/client5.png"
import client6 from "../../images/capabilities/client6.png"
import client7 from "../../images/capabilities/client7.png"
import client8 from "../../images/capabilities/client8.png"
import client9 from "../../images/capabilities/client9.png"
import client10 from "../../images/capabilities/client10.png"
import client11 from "../../images/capabilities/client11.png"
import client12 from "../../images/capabilities/client12.png"
import client13 from "../../images/capabilities/client13.png"

const capabilities = () => (
  <div className="cb_container">
    <div className="cb_header">
      <img src={headerImg} />
    </div>
    <div className="cb_content">
      <div
        className="cb_leftContetnt"
        style={{ backgroundColor: "rgba(180, 180, 180, 0)" }}
      >
        <div className="cb_rightBlock">
          <div className="cb_left_title">
            <img src={icon_logo} style={{ width: "20px", height: "20px" }} />
            <span>OVERVIEW</span>
          </div>
          <div className="cb_left_desc">
            Trident Consulting is an award-winning IT/engineering staffing
            company founded in 2005 and headquartered in San Ramon, CA. We
            specialize in placing high-quality vetted technology and engineering
            professionals in contract and full-time roles. Trident shines in
            fulfilling niche/hard-to-fill technical positions, and our
            commitment is to deliver the best and brightest individuals in the
            industry for our clients' toughest requirements
          </div>
          <div className="cb_left_title">
            <img src={icon_logo} style={{ width: "20px", height: "20px" }} />
            DOMAIN EXPERTISE
          </div>
          <div className="cb_left_desc">
            <div className="col">
              <ul>
                <li> Financial services</li>
                <li> Insurance</li>
                <li> Healthcare</li>
                <li> Government Agencies</li>
                <li> Telecom</li>
              </ul>
            </div>
            <div className="col">
              <ul>
                <li>Manufacturing</li>
                <li>Life Sciences</li>
                <li>Digital Infrastructure</li>
                <li>Automotive</li>
              </ul>
            </div>
          </div>
          <div className="cb_left_title">
            <img src={icon_logo} style={{ width: "20px", height: "20px" }} />
            TECHNOLOGY EXPERTISE
          </div>
          <div className="cb_left_desc">
            <div className="col">
              <ul>
                <li>Software Development</li>
                <li>Web/Mobile Development</li> <li>Big Data</li>
                <li>Cloud Computing</li>
                <li>Cybersecurity</li>
                <li>AI/ML</li>
              </ul>
            </div>
            <div className="col">
              <ul>
                <li>Blockchain</li>
                <li>Edge Computing </li>
                <li>RPA</li>
                <li>ERP</li>
                <li>IoT</li>
              </ul>
            </div>
          </div>
          <div className="cb_left_title">
            <img src={icon_logo} style={{ width: "20px", height: "20px" }} />
            KEY DIFFERENTIATORS
          </div>
          <div className="cb_left_desc">
            <div>
              <ul>
                <li>
                  {
                    "Successful past performance in hard-to-fill & niche requirements"
                  }
                </li>
                <li>
                  Focus on helping clients reach their diversity and inclusion
                  goals
                </li>
                <li>Team of 80+ highly skilled and trained recruiters</li>
                <li>
                  On/off-site business model provides access to a multitude of
                  resources
                </li>
                <li>
                  Continuously updated database of 1.6+ million candidates
                </li>
                <li>Quick turnaround time </li>
                <li>Highly engaged and motivated leadership team </li>
                <li>100% woman and minority owned enterprise</li>
              </ul>
            </div>
          </div>
          <div className="cb_left_title">
            <img src={icon_logo} style={{ width: "20px", height: "20px" }} />
            COMPANY DATA
          </div>
          <div className="cb_left_desc">
            <div>
              <ul>
                <li>DUNS: 00-573-5072</li>
                <li>
                  NAICS Codes: 561311, 561312, 541330, 541612, 541990, 541690
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="cb_rightContetnt">
        <div className="cb_left_title" style={{ width: "50%" }}>
          <img src={icon_logo} style={{ width: "20px", height: "20px" }} />
          {"AWARDS & MEMBERSHIP"}
        </div>
        <div className="cb_left_desc">
          <div>
            <ul>
              <li style={{ width: "60%" }}>
                Inc. 5000 fastest-growing private companies in America 2021
              </li>
              <li style={{ width: "70%" }}>
                Inc. 5000 fastest-growing private companies in California 2021
              </li>
              <li style={{ width: "75%" }}>
                San Francisco Business Times 100 fastest-growing private
                companies in Bay Area 2021
              </li>
              <li style={{ width: "80%" }}>
                WRMSDC Cornerstone of the year award 2021
              </li>
              <li style={{ width: "90%" }}>
                Inc. 5000 fastest-growing private companies in America 2020
              </li>
              <li style={{ width: "95%" }}>
                Enterprising Woman of the year award 2020
              </li>
              <li style={{ width: "100%" }}>
                CEO Shabana Siraj sits on the board of WRMSDC’s MBEIC as
                Secretary
              </li>
              <li>Active member of Tech Serve Alliance & IT Serve Alliance</li>
            </ul>
          </div>
        </div>
        <div className="cb_left_title">
          <img src={icon_logo} style={{ width: "20px", height: "20px" }} />
          CERTIFICATIONS
        </div>
        <div className="cb_left_desc">
          <div className="cb_right_content">
            <img src={cert1} />
            <img src={cert2} />
            <img src={cert3} />
            <img src={cert4} />
            <img src={cert5} />
            <img src={cert6} />
            <img src={cert7} />
            <img src={cert8} />
            <img src={cert9} />
          </div>
        </div>
        <div className="cb_left_title">
          <img src={icon_logo} style={{ width: "20px", height: "20px" }} />
          KEY CLIENTS
        </div>
        <div className="cb_left_desc">
          <div className="cb_right_content">
            <img src={client1} />
            <img src={client2} />
            <img src={client3} />
            <img src={client4} />
            <img src={client5} />
            <img src={client6} />
            <img src={client7} />
            <img src={client8} />
            <img src={client9} />
            <img src={client10} />
            <img src={client11} />
            <img src={client12} />
            <img src={client13} />
          </div>
        </div>
      </div>
    </div>
    <div className="cb_footer">
      <span>6101 Bollinger Canyon Road, San Ramon, CA 94583</span> |{" "}
      <span>
        <a href="https://www.tridentconsultinginc.com">
          Tridentconsultinginc.com
        </a>
      </span>{" "}
      |{" "}
      <span>
        {" "}
        <a href="mailto:info@tridentconsultinginc.com">
          info@tridentconsultinginc.com
        </a>
      </span>{" "}
      | <span>P: 925-353-5850</span>
    </div>
  </div>
)

export default capabilities
